import React from "react";

const NftServises = () => {
  return (
    <>
      <section id="nftservice">
        <h1>
          <span style={{ color: "white" }}>Series of Reldex NFT's</span>
        </h1>
        <div class="nftservice-box-container">
          <div
            class="nftservice-box horse pic1 aos-init aos-animate"
            data-aos="flip-left"
          >
            <img class="race_image" src="assets/images/28.jpg" alt="img2" />
            <div class="box-top">
              <div class="service">
                <div class="name-service">
                  <strong>HORSE</strong>
                </div>
              </div>
            </div>
            <div class="service-detail">
              <p>
                Horse Racing NFTS, where the ownership of extraordinary digital
                collectibles technology.
              </p>
            </div>
          </div>
          <div
            class="nftservice-box horse pic2 aos-init aos-animate"
            data-aos="flip-left"
          >
            <img class="race_image" src="assets/images/22.jpeg" alt="img2" />
            <div class="box-top">
              <div class="service">
                <div class="name-service">
                  <strong>CAR</strong>
                </div>
              </div>
            </div>
            <div class="service-detail">
              <p>
                Car Racing NFTs. Imagine owning digital collectibles that show
                off fast cars and thrilling races.
              </p>
            </div>
          </div>
          <div
            class="nftservice-box horse pic3 aos-init aos-animate"
            data-aos="flip-left"
          >
            <img class="race_image" src="assets/images/23.jpeg" alt="img2" />
            <div class="box-top">
              <div class="service">
                <div class="name-service">
                  <strong>BULL</strong>
                </div>
              </div>
            </div>
            <div class="service-detail">
              <p>
                Bull Race NFTS! We're taking the thrill of bull races and
                blending it with the digital magic.
              </p>
            </div>
          </div>
          <div
            class="nftservice-box horse pic4 aos-init aos-animate"
            data-aos="flip-left"
          >
            <img class="race_image" src="assets/images/24.jpeg" alt="img2" />
            <div class="box-top">
              <div class="service">
                <div class="name-service">
                  <strong>BIKE</strong>
                </div>
              </div>
            </div>
            <div class="service-detail">
              <p>
                Bike Sports NFTs, we're at the intersection of extreme sports
                and blockchain innovation.
              </p>
            </div>
          </div>
          <div
            class="nftservice-box horse pic5 aos-init aos-animate"
            data-aos="flip-left"
          >
            <img class="race_image" src="assets/images/26.jpg" alt="img2" />
            <div class="box-top">
              <div class="service">
                <div class="name-service">
                  <strong>DOG</strong>
                </div>
              </div>
            </div>
            <div class="service-detail">
              <p>
                Dog Race NFT's, we're taking about awesomeness of dog races and
                bringing into the digital world.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NftServises;
