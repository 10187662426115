import React from "react";
import NftServises from "../components/NftServises";
// import ArrowBtn from "../components/ArrowBtn";

const Home = () => {
  return (
    <>
      <>
        <div className="banner-bg">
          {/* <div className="bg-number" /> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <header>
                  <div className="str">
                    <div className="row">
                      <div className="col-md-12">
                        <nav className="navbar navbar-inverse">
                          <div className="navbar-header">
                            <button
                              type="button"
                              className="navbar-toggle"
                              data-toggle="collapse"
                              data-target="#myNavbar"
                            >
                              <span className="icon-bar" />
                              <span className="icon-bar" />
                              <span className="icon-bar" />
                            </button>
                            <a href="/">
                              <img
                                src="assets/images/reldex-logo.png"
                                alt="img2"
                                width="100%"
                              />
                            </a>
                          </div>
                          <div
                            className="collapse navbar-collapse"
                            id="myNavbar"
                          >
                            <ul className="nav navbar-nav navbar-right cl-effect-1">
                              <li>
                                <a href="/">Home</a>
                              </li>
                              <li>
                                <a href="#about">About</a>
                              </li>
                              {/* <li><a href="#nft">NFT</a></li> */}
                              <li>
                                <a href="#token">Token</a>
                              </li>
                              <li>
                                <a href="#roadmap">Roadmap</a>
                              </li>
                              <li>
                                <a href="/" target="blank">
                                  Whitepaper
                                </a>
                              </li>
                              {/* <li>
                                <a href="/" className="top-but1">
                                  <p>Sign In</p>
                                </a>
                              </li>
                              <li>
                                <a href="/" className="top-but2">
                                  <p>Sign Up</p>
                                </a>
                              </li> */}
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </header>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="banner-circle1">
                    <img
                      src="assets/images/robo.png"
                      alt="img2"
                      className="blink"
                    />
                  </div>
                  <div className="banner-text">
                    <h2>
                      Welcome to Reldex Coin - Empowering Financial Freedom
                    </h2>
                    <h3>
                      Reldex Coin (RDX COIN) is your gateway to a new era of
                      digital finance. Designed to offer lightning-fast,
                      low-cost transactions on the Binance Smart Chain, Reldex
                      Coin is changing the way you interact with
                      cryptocurrencies.
                    </h3>
                  </div>
                  <div className="slide1">
                    {/* <a href="/">
                      <button className="signupbut button-slanted1">
                        <span className="button-slanted-content1">
                          &nbsp; Sign In
                        </span>
                        <i />
                      </button>
                    </a>
                    <a href="/">
                      <button className="loginbut button-slanted1">
                        <span className="button-slanted-content1">
                          &nbsp; Sign Up
                        </span>
                        <i />
                      </button>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <img
                    src="assets/images/banner1.png"
                    width="100%"
                    className="vert-move banner"
                    alt="img2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="about-head">
                  <h2>PRODUCT BASED ON BLOCKCHAIN &amp; bsc chain</h2>
                  <h3>about block chain like bsc chain </h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="about-para">
                    <p>
                      Blockchain technology has revolutionized various
                      industries, and one of the most notable developments is
                      the emergence of products
                    </p>
                    <p>
                      {" "}
                      Decentralized exchanges with reduced transaction costs.
                      These products leverage the speed and efficiency of the
                      BSC network to offer users a seamless and cost-effective.
                    </p>

                    <p>
                      Security and Privacy: Reldex Coin utilizes the Binance
                      Smart Chain's robust security features to ensure your
                      transactions are safe and private.
                    </p>
                    <p>
                      Discover the endless possibilities of Reldex Coin. Get
                      started today! and services built on the Binance Smart
                      Chain (BSC).
                    </p>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="plan-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="plan-head">
                  <h3 stle="font-size:25px">
                    JOIN THE FASTEST GROWING ECOSYSTEM
                  </h3>
                </div>
                <div className="plan-common">
                  <p style={{ fontSize: "16px" }}>
                    <b style={{ fontSize: "18px", color: "#bd982c" }}>
                      BSC COMPATIBILITY
                    </b>
                    &#160;-&#160; Binance Smart Chain (BSC) compatibility has
                    become a crucial aspect of the blockchain industry,
                    facilitating interoperability and expanding the capabilities
                    of the BSC ecosystem. BSC compatibility primarily refers to
                    the ability of BSC to work seamlessly with other blockchain
                    networks and protocols.
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    <b style={{ fontSize: "18px", color: "#bd982c" }}>
                      STRONG SECUTIRY
                    </b>
                    &#160;-&#160; Strong security is the cornerstone of trust in
                    our interconnected world. It encompasses a multifaceted
                    approach that includes encryption, authentication, access
                    control, and constant vigilance. In the realm of technology
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    <b style={{ fontSize: "18px", color: "#bd982c" }}>
                      PAYMENT OPTION
                    </b>
                    &#160;-&#160;Digital payment options, including mobile
                    wallets like Apple Pay, Google Pay, and Samsung Pay, have
                    gained popularity for their ease of use. Users can link
                    their debit or credit cards to these wallets and make
                    contactless payments with their smartphones or smartwatches.
                    Additionally, peer-to-peer (P2P) payment apps like Venmo and
                    PayPal have made it simple to transfer money to friends and
                    family instantly.
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    <b style={{ fontSize: "18px", color: "#bd982c" }}>
                      TRANSPARENT INFORMATION
                    </b>
                    &#160;-&#160;Transparent information is a critical concept
                    in various aspects of our lives, from governance and
                    business to personal relationships and decision-making. It
                    refers to the clear, honest, and readily accessible
                    presentation of data, facts, and details, without any
                    intentional distortion, concealment, or manipulation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="calcu-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="ref-bg">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="ref-head1"></div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          <div className="ref-left">
                            <center>
                              <img
                                src="assets/images/16.png"
                                className="floater-2"
                                alt="img2"
                                width="500px"
                              />
                            </center>
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12" />
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <br />
                          <div className="ref-para">
                            <br />
                            <h3>
                              OUR LATEST TECHNOLOGY & INTERESTING FEATURES
                            </h3>
                            <p>
                              Introducing fascinating features that shape the
                              way we live, work, and interact with the world.
                              These innovations drive progress, offering
                              exciting possibilities and improvements in various
                              domains.
                              <br />
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                Blockchain Applications:
                              </b>
                              &#160;-&#160; Blockchain technology has moved
                              beyond cryptocurrencies to offer transparency and
                              security in various industries. It's being used
                              for supply chain tracking, secure voting systems,
                              and digital identity management, promising a
                              future of trust and accountability.
                              <br />
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                Green Technology:
                              </b>
                              &#160;-&#160; Sustainability is a key focus, with
                              advancements in renewable energy sources, electric
                              vehicles, and eco-friendly materials. Solar power,
                              wind turbines, and energy-efficient technologies
                              play a vital role in reducing our environmental
                              footprint.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ref-bg">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="ref-head1"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                          <br />
                          <div className="ref-para">
                            <br />
                            <h2>We are launching </h2>
                            {/* <h3> five nft's</h3> */}
                            <p>
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                1.NFT
                              </b>
                              &#160;Non-Fungible Token, is a revolutionary
                              digital asset that has gained widespread attention
                              and adoption in recent years. Unlike
                              cryptocurrencies like Bitcoin or Ethereum, which
                              are fungible and can be exchanged on a one-to-one
                              basis, NFTs are unique digital tokens that
                              represent ownership of a specific digital item
                            </p>{" "}
                            <p>
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                2.Metaverse
                              </b>
                              &#160;The metaverse is a groundbreaking
                              convergence of technology, including augmented and
                              virtual reality, blockchain, artificial
                              intelligence, and high-speed connectivity. It aims
                              to create a seamless digital ecosystem where users
                              can engage with a multitude of experiences, from
                              gaming and entertainment to education and
                              commerce.
                            </p>{" "}
                            <p>
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                3.Gaming
                              </b>
                              &#160;Non-Fungible Token, is a revolutionary
                              digital asset that has gained widespread attention
                              and adoption in recent years. Unlike
                              cryptocurrencies like Bitcoin or Ethereum, which
                              are fungible and can be exchanged on a one-to-one
                              basis, NFTs are unique digital tokens that
                              represent ownership of a specific digital item
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          <div className="ref-left">
                            <img
                              src="assets/images/17.png"
                              className="floater-2"
                              alt="img2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <NftServises />
                <div className="our-bg">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        style={{ marginTop: "90px" }}
                      >
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          <img
                            src="assets/images/13.png"
                            width="100%"
                            alt="img2"
                          />
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                          <div className="our-head">
                            <h3>Metaverse </h3>
                          </div>
                          <div className="our-para1">
                            <p>
                              The metaverse offers immersive experiences through
                              advanced VR and AR technologies. Users can feel
                              present in digital environments and interact with
                              them in real-time, blurring the line between
                              physical and digital realities.
                              <br /> Socialization is a central aspect of the
                              metaverse. Users can meet friends, attend events,
                              collaborate on projects, and participate in social
                              experiences, fostering a sense of community in a
                              digital realm.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="our-bg">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                          <div className="our-head">
                            <h3>TOTAL CONTROL WITH OUR TRANSACTION SYSTEM</h3>
                          </div>
                          <div className="our-para1">
                            <p>
                              Text Transaction system will never ask you for
                              your wallet keys. Your funds will be securely held
                              on a public listed blockchain within a uniquely
                              created keyless smart contract.
                              <br />
                              It signifies a state where individuals,
                              businesses, and institutions have complete
                              oversight and authority over their financial
                              transactions.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          <img
                            src="assets/images/12.png"
                            width="100%"
                            alt="img2"
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="our-head">
                          <h2 className="our-head4">Ready to Dive In?</h2>
                        </div>
                        <div className="our">
                          <ul className="advan-carousel owl-carousel">
                            <li>
                              <div className="ours">
                                <h4>Create a Wallet </h4>
                                <img
                                  src="assets/images/our1.png"
                                  className="heartbeat"
                                  alt="img2"
                                />
                                <p>
                                  Securely store your Reldex Coins in your
                                  user-friendly wallet.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="ours">
                                <h4>Buy RDX COIN </h4>
                                <img
                                  src="assets/images/our2.png"
                                  className="heartbeat"
                                  alt="img2"
                                />
                                <p>Purchase RDX COIN from trusted exchanges.</p>
                              </div>
                            </li>
                            <li>
                              <div className="ours">
                                <h4>Explore DeFi</h4>
                                <img
                                  src="assets/images/our3.png"
                                  className="heartbeat"
                                  alt="img2"
                                />
                                <p>
                                  Participate in decentralized finance and grow
                                  your assets.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="ours">
                                <h4>Join the Community </h4>
                                <img
                                  src="assets/images/our4.png"
                                  className="heartbeat"
                                  alt="img2"
                                />
                                <p>
                                  Stay updated and connect with like-minded
                                  individuals on our social media channels.
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="ref-bg" id="about">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        // style={{ marginBottom: "140px" }}
                      >
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="ref-left">
                            <img
                              src="assets/images/18.png"
                              className="floater-2"
                              width="100%"
                              alt="img2"
                            />
                          </div>
                        </div>
                        <div className="ref-head2">
                          <h3>nft information</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="ref-para p_text_Lh">
                            <p>
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                welcome to reldex crypto:
                              </b>
                              <br /> Reldex is poised to revolutionize the world
                              of digital assets with its innovative offerings,
                              user-friendly interfaces and commitment to
                              security.Your journey with Reldex, you'll discover
                              a platform empowers you to invest, and engage with
                              cryptocurrencies like never before.
                            </p>
                            <p>
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                welcome are reldex crypto collect next
                                generation:
                              </b>
                              <br />
                              Collecting the next generation of digital assets.
                              Reldex is at the forefront of innovation, curating
                              and providing access to the most promising and
                              cutting-edge cryptocurrencies.
                            </p>
                            <p>
                              <b style={{ fontSize: "18px", color: "#bd982c" }}>
                                what is nft?
                              </b>
                              <br />
                              Each NFT is one-of-a-kind, making it ideal for
                              representing digital or physical assets like art,
                              collectibles, music, virtual real estate, in-game
                              items, and more.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section
                  id="nft"
                  className="innerpage_banner tokenSection"
                  style={{ display: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="TokenInforImg">
                          <img
                            src="assets/images/01.jpg"
                            alt="img2"
                            // style={{ marginTop: 65 }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="nft_content">
                          <div className="full">
                            <div className="inner_page_info">
                              <p>Reldex Coin NFT Platform</p>
                              <h3>Unleashing Artistic Potential</h3>
                            </div>
                          </div>
                          <p>
                            Get ready to witness a groundbreaking platform that
                            will revolutionize the NFT landscape for artists and
                            musicians—the Reldex Coin NFT Platform. In the
                            coming years, we are set to launch a dedicated space
                            where creators can unleash their artistic potential
                            by creating and deploying their unique NFTs. This
                            platform will empower artists to showcase their
                            digital artistry, connect with a global audience,
                            and explore new avenues for monetization. With the
                            power of blockchain technology, the Reldex Coin NFT
                            Platform will ensure the security, authenticity, and
                            value of each NFT, providing artists with a trusted
                            ecosystem to thrive. Stay tuned for an immersive
                            experience that will redefine the future of digital
                            art and music.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="ref-bg">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="ref-head1"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                          <br />
                          <div className="ref-para">
                            <br />
                            <h2>Reldex NFT Market Place</h2>
                            <h1>A world of unique digital collectibles</h1>
                            <p>
                              &#160;Join a thriving community of artists and
                              collectors, as we revolutionize the way you buy,
                              sell, and trade NFTs. Stay tuned for the launch of
                              the Gravity Token NFT Marketplace, where digital
                              innovation meets boundless possibilities.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          <div className="ref-left">
                            <img
                              src="assets/images/20.png"
                              className="floater-2"
                              alt="img2"
                              width="500px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section id="token" className="innerpage_banner tokenSection">
                  <div class="container">
                    <div class="chart-inner-2">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-lg-12 col-md-12">
                          <div class="section-title text-center">
                            <span class="sub-title">Tokenomics</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">Token Name</h6>
                              <h6 class="token-details-text token-detail-basic">
                                Reldex Coin
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">Short Name</h6>
                              <h6 class="token-details-text token-detail-basic">
                                RDX Coin
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text ">Total Supply</h6>
                              <h6 class="token-details-text token-detail-basic">
                                20.11 M
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">
                                Instant Generate
                              </h6>
                              <h6 class="token-details-text token-detail-basic">
                                10.5 M
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">Lock</h6>
                              <h6 class="token-details-text token-detail-basic">
                                10.6 M (5 years)
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">Decimal</h6>
                              <h6 class="token-details-text token-detail-basic">
                                18
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">Website Name</h6>
                              <h6 class="token-details-text token-detail-basic">
                                reldex.io
                              </h6>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                            <div class="token-detail btn2">
                              <h6 class="token-details-text">Listing</h6>
                              <h6 class="token-details-text token-detail-basic">
                                $0.25 Cent
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="token-dist">
                  <h1>Token Distribution</h1>
                  <div className="col-md-12 imgtoken">
                    <img
                      src="assets/images/tokenomics2.png"
                      alt="img2"
                      width="86%"
                    />
                  </div>
                </section>
                <section>
                  <div className="subc-area section-divide" id="roadmap">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="full">
                            <div className="inner_page_info">
                              <h3>Roadmap</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 body5">
                              <ul className="setul">
                                <li style={{ color: "#41516C" }}>
                                  <div
                                    className="date"
                                    style={{ backgroundColor: "#41516C" }}
                                  >
                                    2023 Q4
                                  </div>
                                  <div className="title">RDX Token</div>
                                  <div className="descr">
                                    Imagine owning digital collectibles that
                                    show off fast cars and thrilling races.
                                    We're bringing together our love for racing
                                    with the power of blockchain.
                                  </div>
                                </li>
                                <li style={{ color: "#FBCA3E" }}>
                                  <div
                                    className="date"
                                    style={{ backgroundColor: "#FBCA3E" }}
                                  >
                                    2024 Q1
                                  </div>
                                  <div className="title">NFT</div>
                                  <div className="descr">
                                    Imagine owning digital collectibles that
                                    show off fast cars and thrilling races.
                                    We're bringing together our love for racing
                                    with the power of blockchain.
                                  </div>
                                </li>
                                <li style={{ color: "#E24A68" }}>
                                  <div
                                    className="date"
                                    style={{ backgroundColor: "#E24A68" }}
                                  >
                                    2024 Q2
                                  </div>
                                  <div className="title">Networking</div>
                                  <div className="descr">
                                    Imagine owning digital collectibles that
                                    show off fast cars and thrilling races.
                                    We're bringing together our love for racing
                                    with the power of blockchain.
                                  </div>
                                </li>
                                <li style={{ color: "#1B5F8C" }}>
                                  <div
                                    className="date"
                                    style={{ backgroundColor: "#1B5F8C" }}
                                  >
                                    2024 Q3
                                  </div>
                                  <div className="title">Metaverse</div>
                                  <div className="descr">
                                    Imagine owning digital collectibles that
                                    show off fast cars and thrilling races.
                                    We're bringing together our love for racing
                                    with the power of blockchain.
                                  </div>
                                </li>
                                <li style={{ color: "#4CADAD" }}>
                                  <div
                                    className="date"
                                    style={{ backgroundColor: "#4CADAD" }}
                                  >
                                    2024 Q4
                                  </div>
                                  <div className="title">gaming metaverse</div>
                                  <div className="descr">
                                    Imagine owning digital collectibles that
                                    show off fast cars and thrilling races.
                                    We're bringing together our love for racing
                                    with the power of blockchain.
                                  </div>
                                </li>
                                <li style={{ color: "#41516C" }}>
                                  <div
                                    className="date"
                                    style={{ backgroundColor: "#41516C" }}
                                  >
                                    2025 Q1
                                  </div>
                                  <div className="title">Live Gaming</div>
                                  <div className="descr">
                                    Imagine owning digital collectibles that
                                    show off fast cars and thrilling races.
                                    We're bringing together our love for racing
                                    with the power of blockchain.
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="foot-bg">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 footer-upper">
                        <div className="foot-logo">
                          <a href="/">
                            <img
                              src="assets/images/reldex-logo.png"
                              width="100px"
                              alt="img2"
                            />
                          </a>
                        </div>
                        <div className="tele">
                          <a
                            href="https://t.me/reldex_coin_official"
                            target="blank"
                          >
                            <i className="fa fa-paper-plane" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="foot-bg1">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 foot-in">
                        <div className="col-md-4">
                          Reldex Coin (RDX COIN) is your gateway to a new era of
                          digital finance. Designed to offer lightning-fast,
                          low-cost transactions on the Binance Smart Chain.
                        </div>
                        <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
                          <div className="foot-menu">
                            <ul>
                              <li>
                                <a href="index.html">Home</a>
                              </li>
                              <li>
                                <a href="#about">About us</a>
                              </li>
                              {/* <li><a href="#nft">NFT</a></li> */}
                              <li>
                                <a href="#token">Token</a>
                              </li>
                              <li>
                                <a href="#roadmap">Roadmap</a>
                              </li>
                              <li>
                                <a href="/" target="blank">
                                  Whitepaper
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-md-6">
                          <div className="copy">
                            <p>
                              Copyright 2023 © reldex.io All Rights Reserved
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="social_links">
                            <ul>
                              <li>
                                <a
                                  href="https://www.facebook.com/profile.php?id=61550900430959&is_tour_dismissed=true"
                                  target="blank"
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://twitter.com/RCoin011"
                                  target="blank"
                                >
                                  <i className="fa fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/"
                                  target="blank"
                                >
                                  <i className="fa fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.youtube.com/channel/UC9vlW1mGkhRfqMr3B8aHuyg"
                                  target="blank"
                                >
                                  <i className="fa fa-youtube" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Home;
